<template>
  <v-card rounded="lg" flat class="pa-4 message-component-height">
    <div
      ref="contactList"
      @scroll="scrollContacts()"
      :class="
        `contact-list ${loading ? 'overflow-y-hidden' : 'overflow-y-auto'}`
      "
    >
      <contact-skeleton-loader v-if="loading"></contact-skeleton-loader>
      <!-- <template v-if="repositoryKey === 'dream-team'"> -->
        <div v-if="!loading && dreamTeamConversations.length > 0">
          <template v-for="conversation in dreamTeamConversations">
            <v-card
              :key="conversation.id"
              flat
              class="my-2 pa-4"
              :class="conversation.class.card"
              rounded="lg"
              @click.native="readConversation(conversation)"
            >
              <span class="cursor-pointer">
                <div class="d-flex align-center">
                  <div
                    class="font-weight-bold "
                    :class="conversation.class.name"
                  >
                    {{ conversation.name }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-badge
                    v-show="conversation.unreadCount"
                    inline
                    dot
                    color="red"
                  >
                  </v-badge>
                </div>
                <div class="caption" :class="conversation.class.message">
                  {{ conversation.lastMessage | TRUNCATE_TEXT }}
                </div>
              </span>
            </v-card>
          </template>
        </div>
      <!-- </template> -->

      <!-- <template v-if="repositoryKey === 'connect-group'">
        <div v-if="!loading && connectGroupConversations.length > 0">
          <template v-for="conversation in connectGroupConversations">
            <v-card
              :key="conversation.id"
              flat
              class="my-2 pa-4"
              :class="conversation.class.card"
              rounded="lg"
              @click.native="readConversation(conversation)"
            >
              <span class="cursor-pointer">
                <div class="d-flex align-center">
                  <div
                    class="font-weight-bold "
                    :class="conversation.class.name"
                  >
                    {{ conversation.name }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-badge
                    v-show="conversation.unreadCount"
                    inline
                    dot
                    color="red"
                  >
                  </v-badge>
                </div>
                <div class="caption" :class="conversation.class.message">
                  {{ conversation.lastMessage | TRUNCATE_TEXT }}
                </div>
              </span>
            </v-card>
          </template>
        </div>
      </template> -->

      <!-- <template v-if="repositoryKey === 'pastor-chat'">
        <div v-if="!loading && pastorChatConversations.length > 0">
          <template v-for="conversation in pastorChatConversations">
            <v-card
              :key="conversation.id"
              flat
              class="my-2 pa-4"
              :class="conversation.class.card"
              rounded="lg"
              @click.native="readConversation(conversation)"
            >
              <span class="cursor-pointer">
                <div class="d-flex align-center">
                  <div
                    class="font-weight-bold "
                    :class="conversation.class.name"
                  >
                    {{ conversation.name }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-badge
                    v-show="conversation.unreadCount"
                    inline
                    dot
                    color="red"
                  >
                  </v-badge>
                </div>
                <div class="caption" :class="conversation.class.message">
                  {{ conversation.lastMessage | TRUNCATE_TEXT }}
                </div>
              </span>
            </v-card>
          </template>
        </div>
      </template> -->
    </div>
  </v-card>
</template>

<script>
import Storage from "@/services/storage.js";
import { CHAT_SUPPORT_EVENT_BUS } from "../js/ChatSupportEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import ContactSkeletonLoader from "@/views/chat_support/skeleton-loaders/ContactSkeletonLoader";
import {
  // GET_CONNECT_GROUP_CONVERSATIONS,
  GET_DREAM_TEAM_CONVERSATIONS,
  // GET_PASTOR_CONVERSATIONS,
  READ_CONNECT_GROUP_CONVERSATION_MESSAGE,
  READ_DREAM_TEAM_CONVERSATION_MESSAGE,
  READ_PASTOR_CONVERSATION_MESSAGE,
  SET_CONNECT_GROUP_CONVERSATION_LAST_MESSAGE,
  // SET_CONNECT_GROUP_CONVERSATION_LAST_MESSAGE,
  SET_CONNECT_GROUP_CONVERSATION_SELECTED_THREAD,
  SET_DREAM_TEAM_CONVERSATION_LAST_MESSAGE,
  // SET_DREAM_TEAM_CONVERSATION_LAST_MESSAGE,
  SET_DREAM_TEAM_CONVERSATION_SELECTED_THREAD,
  SET_PASTOR_CONVERSATION_LAST_MESSAGE,
  // SET_PASTOR_CONVERSATION_LAST_MESSAGE,
  SET_PASTOR_CONVERSATION_SELECTED_THREAD,
  SET_SELECTED_THREAD_ID
} from "@/store/modules/chat.module";
import CryptoJS from "crypto-js";
import {
  ADD_PUSHER_CHANNEL,
  CLEAR_PUSHER_CHANNEL
} from "@/store/modules/pusher.module";

// eslint-disable-next-line no-unused-vars
const messagesRepository = RepositoryFactory.get("message");

let storage = new Storage();

export default {
  components: { ContactSkeletonLoader },

  filters: {
    TRUNCATE_TEXT: function(value) {
      if (value && value.length > 120) {
        return `${value.substring(0, 119)}...`;
      }

      return value;
    }
  },

  data: () => ({
    contacts: [],
    repositoryKey: "dream-team", //see ChatGroupCards.vue chatGroups[key].value
    filters: {
      page: 1
    },
    selectedThreadId: null,
    lastPage: 1,
    loading: false
  }),

  computed: {
    globalLocationID() {
      return this.$store.state.eventModule.locationID || null;
    },

    dreamTeamConversations() {
      return this.$store.state.chat.dreamTeam.items;
    },

    connectGroupConversations() {
      return this.$store.state.chat.connectGroup.items;
    },

    pastorChatConversations() {
      return this.$store.state.chat.pastorChat.items;
    }
  },

  watch: {
    async globalLocationID(value) {
      if (value) {
        this.filters.location_id = value;
        await this.getContactsViaLocation();
      }
    },

    "$store.state.chat.dreamTeam.items"(value) {
      this.subscribeToThreadsChannel(value);
    },

    "$store.state.chat.connectGroup.items"(value) {
      this.subscribeToThreadsChannel(value);
    },

    "$store.state.chat.pastorChat.items"(value) {
      this.subscribeToThreadsChannel(value);
    }
  },

  methods: {
    setSelectedContact(conversation) {
      if (this.repositoryKey === "dream-team") {
        this.$store.commit(
          SET_DREAM_TEAM_CONVERSATION_SELECTED_THREAD,
          conversation
        );
      }

      if (this.repositoryKey === "connect-group") {
        this.$store.commit(
          SET_CONNECT_GROUP_CONVERSATION_SELECTED_THREAD,
          conversation
        );
      }

      if (this.repositoryKey === "pastor-chat") {
        this.$store.commit(
          SET_PASTOR_CONVERSATION_SELECTED_THREAD,
          conversation
        );
      }
    },

    readConversation(conversation) {
      CHAT_SUPPORT_EVENT_BUS.$emit("showContactConversations", {
        contact: conversation,
        repositoryKey: this.repositoryKey
      });
      this.setSelectedContact(conversation);
      let isSameId = this.selectedThreadId === conversation.id;
      // let isSameValue = this.repositoryKey === conversation.value;
      if (!isSameId) {
        this.selectedThreadId = conversation.id;
        this.$store.commit(SET_SELECTED_THREAD_ID, this.selectedThreadId);
        const readMessageStoreFunctionValues = {
          "dream-team": READ_DREAM_TEAM_CONVERSATION_MESSAGE,
          "connect-group": READ_CONNECT_GROUP_CONVERSATION_MESSAGE,
          "pastor-chat": READ_PASTOR_CONVERSATION_MESSAGE
        };
        this.$store.commit(
          readMessageStoreFunctionValues[this.repositoryKey],
          conversation.id
        );
        let data = {
          contact: conversation,
          repositoryKey: this.repositoryKey
        };
        CHAT_SUPPORT_EVENT_BUS.$emit(
          "setCurrentConversationChannel",
          this.getConversationChannel(conversation)
        );
        CHAT_SUPPORT_EVENT_BUS.$emit("setCurrentConversationID", data);
      }
    },

    getConversationChannel(contact) {
      return this.$store.state.pusher.channels.find(
        channel => parseInt(channel.split(".")[1]) === contact.id
      );
      // return this.$pusher
      //   .allChannels()
      //   .find(channel => parseInt(channel.name.split(".")[1]) === contact.id);
    },

    scrollContacts() {
      let scrollPosition = this.$refs.contactList;
      let scrollHeight =
        scrollPosition.scrollHeight - scrollPosition.clientHeight;
      if (scrollPosition.scrollTop >= scrollHeight) {
        this.getConversations();
      }
    },

    setContactRepositoryEventBus() {
      CHAT_SUPPORT_EVENT_BUS.$off("setContactRepository");
      CHAT_SUPPORT_EVENT_BUS.$on("setContactRepository", async data => {
        this.repositoryKey = data;
        // this.contacts = [];
        // this.lastPage = 1;
        // this.filters = {
        //   page: 1
        // };
        // await this.setLocationId();
        await this.getConversations();
      });
    },

    sendContactToTopEventBus() {
      CHAT_SUPPORT_EVENT_BUS.$off("sendContactToTop");
      CHAT_SUPPORT_EVENT_BUS.$on("sendContactToTop", data => {
        let threadIndex = this.contacts.findIndex(
          item => item.id === data.threadId
        );
        this.contacts[threadIndex].lastMessage = data.message;
        this.contacts.unshift(this.contacts.splice(threadIndex, 1)[0]);
      });
    },

    setupEventBus() {
      this.setContactRepositoryEventBus();
      this.sendContactToTopEventBus();
    },

    async getConversations() {
      // if (this.repositoryKey === "dream-team") {
        await this.$store.dispatch(
          GET_DREAM_TEAM_CONVERSATIONS,
          this.globalLocationID
        );
      // }

      // if (this.repositoryKey === "connect-group") {
      //   await this.$store.dispatch(
      //     GET_CONNECT_GROUP_CONVERSATIONS,
      //     this.globalLocationID
      //   );
      // }

      // if (this.repositoryKey === "pastor-chat") {
      //   await this.$store.dispatch(
      //     GET_PASTOR_CONVERSATIONS,
      //     this.globalLocationID
      //   );
      // }
      CHAT_SUPPORT_EVENT_BUS.$emit("setDisableChatGroupCards", false);

      // this.selectedThreadId = null;
      // let params = {
      //   with_user_only: true,
      //   no_newly_made_chats: true,
      //   ...this.filters,
      //   location_id: this.filters.location_id || this.globalLocationID
      // };
      // if (this.filters.page === 1) this.loading = true;
      // messagesRepository
      //   .getConversations(this.repositoryKey, { params })
      //   .then(response => {
      //     let data = response.data.responseData;
      //     this.lastPage = data.last_page;
      //     if (data.current_page <= data.last_page) {
      //       this.filters.page++;
      //     }
      //     this.subscribeToThreadsChannel(data.data);
      //     let contacts = data.data.map(item => this.toContactObject(item));
      //     this.contacts.push(...contacts);
      //     CHAT_SUPPORT_EVENT_BUS.$emit("setDisableChatGroupCards", false);
      //     this.loading = false;
      //   })
      //   .catch(error => {
      //     this.loading = false;
      //     console.log({ error });
      //   });
    },

    getContactsViaLocation() {
      this.filters.page = 1;
      this.contacts = [];
      this.getConversations();
      CHAT_SUPPORT_EVENT_BUS.$emit("clearMessages");
    },

    setLocationId() {
      this.filters.location_id = storage.get("location_id") || null;
    },

    subscribeToThreadsChannel(conversations) {
      if (conversations.length > 0) {
        conversations.forEach(conversation => {
          const channelList = {
            "dream-team-chat": `private-dream-team-chat.${conversation.id}`,
            "pastor-chat": `private-pastor-chat.${conversation.id}`,
            "connect-group-chat": `private-connect-group-chat.${conversation.id}`
          };
          // let channel = null;
          const channelName = channelList[`${conversation.value}-chat`];
          // const alreadySubscribed = !!this.$pusher
          //   .allChannels()
          //   .map(channel => channel.name)
          //   .find(name => name === channelName);
          const alreadySubscribed = !!this.$store.state.pusher.channels.find(
            name => name === channelName
          );
          if (!alreadySubscribed) {
            this.toSubscribePrivateChannel(channelName);
          }

          this.$pusher.connection.bind("message", obj => {
            console.log(obj);
            if (obj.event === "App\\Events\\DreamTeamChatMessageCreated") {
              console.log(obj.data.message);
              this.$store.commit(
                SET_DREAM_TEAM_CONVERSATION_LAST_MESSAGE,
                obj.data.message
              );
            }

            if (obj.event === "App\\Events\\ConnectGroupChatMessageCreated") {
              console.log(obj.data.message);
              this.$store.commit(
                SET_CONNECT_GROUP_CONVERSATION_LAST_MESSAGE,
                obj.data.message
              );
            }

            if (obj.event === "App\\Events\\PastorChatMessageCreated") {
              console.log(obj.data.message);
              this.$store.commit(
                SET_PASTOR_CONVERSATION_LAST_MESSAGE,
                obj.data.message
              );
            }
          });
        });
      }
    },

    toSubscribePrivateChannel(channelName) {
      if (!this.$store.state.pusher.channels.includes(channelName)) {
        if (channelName.includes("private-")) {
          let value = this.$pusher.connection.socket_id + ":" + channelName;

          const signature = CryptoJS.HmacSHA256(
            value,
            process.env.VUE_APP_PUSHER_SECRET
          ).toString(CryptoJS.enc.Hex);

          let data = {
            auth: process.env.VUE_APP_PUSHER_KEY + ":" + signature,
            channel: channelName
          };

          this.$pusher.send_event("pusher:subscribe", data);
        } else {
          this.$pusher.subscribe(channelName);
        }
        this.$store.dispatch(ADD_PUSHER_CHANNEL, channelName);
      }
    },

    unsubscribeToThreadsChannel() {
      // this.$pusher.allChannels().forEach(channel => {
      //   this.$pusher.unsubscribe(channel.name);
      // });

      this.$store.state.pusher.channels.forEach(channelName => {
        // console.log("unsubscribe: ", channelName);
        let data = {
          channel: channelName
        };
        this.$pusher.send_event("pusher:unsubscribe", data);
      });
      this.$store.dispatch(CLEAR_PUSHER_CHANNEL);
    }
  },

  async created() {
    await this.$store.dispatch(
      GET_DREAM_TEAM_CONVERSATIONS,
      this.globalLocationID
    );
    // await this.$store.dispatch(
    //   GET_CONNECT_GROUP_CONVERSATIONS,
    //   this.globalLocationID
    // );
    // await this.$store.dispatch(GET_PASTOR_CONVERSATIONS, this.globalLocationID);
    this.setupEventBus();
  },

  destroyed() {
    this.unsubscribeToThreadsChannel();
  }
};
</script>

<style lang="scss" scoped>
.contact-list {
  height: 100%;
}
.outline {
  &-thick {
    border: 3px solid #4ec8eb; /**$main-header-secondary */
  }
}
</style>
