import { render, staticRenderFns } from "./ContactListCard.vue?vue&type=template&id=6b6420a0&scoped=true&"
import script from "./ContactListCard.vue?vue&type=script&lang=js&"
export * from "./ContactListCard.vue?vue&type=script&lang=js&"
import style0 from "./ContactListCard.vue?vue&type=style&index=0&id=6b6420a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b6420a0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VCard,VSpacer})
